<template>
  <div class="week-deal-container">
    <div class="title1">
      月交易趋势
    </div>
    <div class="line"></div>

    <div v-show="length != 0">
      <div
        id="month_echarts"
        style=" width: 7.725rem;
          height: 3.4rem;"
        ref="month_echarts"
      ></div>
    </div>
    <div v-show="length == 0" class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { weekDeal2Serve } from "./api";
import moment from "moment";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    areaData: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      length: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.iniChart();
    });
    this.timer = setInterval(() => {
      // 要执行的函数
      this.iniChart();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async iniChart() {
      const { code, data } = await weekDeal2Serve({
        action: "order.trend.get",
        type: this.areaData,
      });
      this.length = data.length;
      // var max_money = parseInt(Math.max(...data.map((c) => c.money)) / 100);
      // var max_num = parseInt(Math.max(...data.map((c) => c.num)));
      // var multiple = max_money / max_num;
      if (code == 0 && data.length > 0) {
        //放大值

        // var myChart = this.$echarts.init(
        //   document.getElementById("month_echarts")
        // );
        var myChart = this.$echarts.init(this.$refs.month_echarts);

        // 指定图表的配置项和数据
        var option = {
          tooltip: {
            trigger: "item",
            axisPointer: {
              //坐标轴指示器
              type: "none", //十字准星指示器
            },
          },
          legend: {
            data: ["交易额", "交易笔数"],
            top: 30,
            left: "60%",
            textStyle: { fontSize: 14, color: "#ffffff" },
            // align: "left",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            data: data.map((item) => moment.unix(item.time).format("MM/DD")),
            axisLabel: {
              color: "#fff",
              textStyle: {
                fontSize: "18",
              },
            },
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#1F297B", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
          },
          yAxis: [
            {
              type: "value",
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                color: "#fff",
                textStyle: {
                  fontSize: "18",
                },
              },

              //轴线
              axisLine: {
                show: true,
                lineStyle: {
                  type: "solid",
                  color: "#1F297B", //左边线的颜色
                  width: "1", //坐标线的宽度
                },
              },
              //分割线
              splitLine: {
                show: false,
              },
            },
            {
              type: "value",
              axisLabel: {
                color: "#fff",
                textStyle: {
                  fontSize: "18",
                },
              },
              //轴线
              axisLine: {
                show: true,
                lineStyle: {
                  type: "solid",
                  color: "#1F297B", //左边线的颜色
                  width: "1", //坐标线的宽度
                },
              },

              //分割线
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "交易额",
              type: "line",
              yAxisIndex: 0,
              data: data.map((item) => (item.money / 100).toFixed(2)),

              smooth: true,
              symbol: "none",
              // symbolSize: 8, // 拐点大小
              itemStyle: {
                normal: {
                  color: "#FFDB10", // 拐点颜色
                },
              },
              lineStyle: {
                normal: {
                  color: "#FFDB10", // 折线颜色
                },
              },
              areaStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(255,245,14,0.8)",
                  },
                  {
                    offset: 1,
                    color: "rgba(74,235,255,0)",
                  },
                ]),
              },
            },
            {
              name: "交易笔数",
              type: "bar",

              yAxisIndex: 1,

              barWidth: "70%",
              symbol: "none",
              itemStyle: {
                // 使用方法二的写法
                color: {
                  type: "linear",
                  x: 0, //右
                  y: 0, //下
                  x2: 0, //左
                  y2: 1, //上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#5BFEFF", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#235FFF", // 100% 处的颜色
                    },
                  ],
                },
              },
              data: data.map((item) => item.num),
            },
          ],
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.week-deal-container {
  background: url(https://www.chuansmart.com/storage/screen/div-bg.png)
    no-repeat;
  background-size: 7.725rem 3.975rem;
  width: 7.725rem;
  height: 3.975rem;
  margin-bottom: 0.1rem;
  .title1 {
    font-size: 0.25rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 0.15rem;
  }
  .line {
    width: 5.375rem;
    height: 0.025rem;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 0.15rem auto 0;
  }
  .deal-content {
    width: 3rem;
    height: 2rem;
    transition: all 1.5s;
    background: url(https://www.chuansmart.com/storage/screen/deal-icon.png)
      no-repeat;
    background-size: 3rem 2rem;
    position: relative;
    .num {
      font-size: 0.45rem;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #41fe93;
      text-align: center;
      position: absolute;
      top: 0.625rem;
      width: 100%;
    }
    .text {
      font-size: 0.175rem;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 1.125rem;
      width: 100%;
    }
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 0.175rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 2.25rem;
      height: 1.25rem;
    }
  }
}
</style>
