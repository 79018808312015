<template>
  <div>
    <div v-if="deal_arr.length != 0">
      <div class="market-collect-container3" v-if="deal_arr.length == 3">
        <ul>
          <li v-for="(item, index) in deal_arr" :key="index">
            <div class="deal-content">
              <div class="num">{{ item.num }}</div>
              <div class="text">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="market-collect-container4" v-if="deal_arr.length == 4">
        <ul>
          <li v-for="(item, index) in deal_arr" :key="index">
            <div class="deal-content">
              <div class="num">{{ item.num }}</div>
              <div class="text">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="market-collect-container5" v-if="deal_arr.length == 5">
        <ul>
          <li v-for="(item, index) in deal_arr" :key="index">
            <div class="deal-content">
              <div class="num">{{ item.num }}</div>
              <div class="text">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="market-collect-container3" v-if="deal_arr.length == 3">
        <ul>
          <li v-for="(item, index) in deal_arr" :key="index">
            <div class="deal-content">
              <div class="num">{{ item.num }}</div>
              <div class="text">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>
     
      -->
      <div class="market-collect-container6" v-if="deal_arr.length >= 6">
        <ul>
          <li v-for="(item, index) in deal_arr1" :key="index">
            <div class="deal-content">
              <div class="num">{{ item.num }}</div>
              <div class="text">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="market-collect-container6">
        <el-carousel :interval="3000" type="card" :autoplay="true">
          <el-carousel-item v-for="(item, index) in deal_arr" :key="index">
            <div class="collect_content">
              <div class="num">{{ item.num }}</div>
              <div class="text">{{ item.name }}</div>
            </div>
          </el-carousel-item>
        </el-carousel> -->
      <!-- <ul>
          <li v-for="(item, index) in deal_arr" :key="index">
            <div class="deal-content">
              <div class="num">{{ item.num }}</div>
              <div class="text">{{ item.name }}</div>
            </div>
          </li>
        </ul> -->
      <!-- </div> -->
      <!-- :autoplay="true" :display="5" :animationSpeed="1500" -->
      <!-- <carousel-3d perspective="0" :autoplay="true">
        <slide v-for="(slide, i) in deal_arr" :key="i" :index="i">
          <div class="collect_content">
            <div class="num">{{ slide.num }}</div>
            <div class="text">{{ slide.name }}</div>
          </div>
        </slide>
      </carousel-3d> -->
    </div>
    <div v-else class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { marketCollect2Serve } from "./api";
// import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "",
  components: {
    // Carousel3d,
    // Slide,
  },
  mixins: [],
  props: {
    areaData: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      deal_arr: [],
      new_arr1: [],
      deal_arr1: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getMarletCollect();
    this.timer = setInterval(() => {
      // 要执行的函数
      this.getMarletCollect();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async getMarletCollect() {
      const { code, data } = await marketCollect2Serve({
        action: "top.get",
        type: this.areaData,
      });
      if (code == 0) {
        this.deal_arr = this.areaData.map((item) => {
          return {
            name:
              item == 1
                ? "今日交易额"
                : item == 2
                ? "交易笔数"
                : item == 3
                ? "商户数量"
                : item == 4
                ? "日客流量"
                : item == 5
                ? "本月巡查"
                : item == 6
                ? "本月检测"
                : item == 7
                ? "检测合格率"
                : item == 8
                ? "铺位数"
                : item == 9
                ? "周交易笔数"
                : item == 10
                ? "月交易笔数"
                : item == 11
                ? "周交易额"
                : item == 12
                ? "月交易额"
                : item == 13
                ? "周客流量"
                : item == 14
                ? "月客流量"
                : item == 15
                ? "计量称重"
                : item == 16
                ? "计量金额"
                : item == 17
                ? "当日检测"
                : item == 18
                ? "本周检测"
                : item == 19
                ? "日客单价"
                : item == 20
                ? "周客单价"
                : "月客单价",
            num:
              item == 1
                ? (data.sale_money / 100).toFixed(2)
                : item == 2
                ? data.sale_num
                : item == 3
                ? data.merchant_num
                : item == 4
                ? data.flow_num
                : item == 5
                ? data.inspection_num
                : item == 6
                ? data.mouth_quality_num
                : item == 7
                ? data.qualified_rate
                : item == 8
                ? data.floor_num
                : item == 9
                ? data.week_order_num
                : item == 10
                ? data.month_order_num
                : item == 11
                ? (data.week_order_amount / 100).toFixed(2)
                : item == 12
                ? (data.month_order_amount / 100).toFixed(2)
                : item == 13
                ? data.week_flow_num
                : item == 14
                ? data.month_flow_num
                : item == 15
                ? data.weighting_num
                : item == 16
                ? (data.weighting_amount / 100).toFixed(2)
                : item == 17
                ? data.day_quality_num
                : item == 18
                ? data.week_quality_num
                : item == 19
                ? (data.sale_rate / 100).toFixed(2)
                : item == 20
                ? (data.week_sale_rate / 100).toFixed(2)
                : (data.month_sale_rate / 100).toFixed(2),
          };
        });
        console.log(this.deal_arr, "this.deal_arr");
        if (this.deal_arr.length >= 6) {
          this.deal_arr1 = this.deal_arr.slice(0, 6);
          this.new_arr1 = this.deal_arr.slice(6, this.deal_arr.length);

          console.log(this.deal_arr1);
          var times= setInterval(() => {
            // var new_arr = this.deal_arr.slice(0, 6);
            console.log(this.deal_arr.length);
            console.log(this.new_arr1, "new_arr");
            if (this.new_arr1.length > 6) {
              this.deal_arr1 = this.new_arr1.slice(0, 6);
              this.new_arr1 = this.new_arr1.slice(6, this.new_arr1.length);
            } else {
              this.new_arr1 = this.new_arr1.concat(this.deal_arr);
              this.deal_arr1 = this.new_arr1.slice(0, 6);
            }
            clearInterval(times);
            // console.log(new_arr1, "new_arr1");
            // this.deal_arr1 = new_arr1.concat(this.deal_arr).slice(0, 6);
            // console.log(this.deal_arr1, "new_arr1");
          }, 10000);
          // var new_arr = this.deal_arr.slice(0, 6);
          // var new_arr1 = this.deal_arr.slice(6);
          // console.log(new_arr, "new_arr");
          // console.log(new_arr1, "new_arr1");

          // var num =
          //   (6 * this.deal_arr.length) / this.gcd(6, this.deal_arr.length);
          // console.log(num);
          // this.merchantList = this.merchantList.map((item, index) => {
          //   return {
          //     item:
          //       item.length != 6
          //         ? item.concat(this.merchantList[index - 1]).slice(0, 6)
          //         : item,
          //   };
          // });
          // setInterval(() => {
          //   // this.deal_arr = this.deal_arr.slice(0, 5);
          //   var arr = this.deal_arr;
          //   var result = [];
          //   var ranNum = 6;
          //   for (var i = 0; i < ranNum; i++) {
          //     var ran = Math.floor(Math.random() * (arr.length - i));
          //     result.push(arr[ran]);
          //     arr[ran] = arr[arr.length - i - 1];
          //   }
          //   this.deal_arr = result;
          // }, 5000);
        }
      }
    },
    gcd(a, b) {
      if (b == 0) {
        return a;
      }
      var r = a % b;
      console.log(r);
      // return gcd(b, r);
    },
    GCD(a, b) {
      var temp;
      while (b != 0) {
        temp = a % b;
        a = b;
        b = temp;
      }
      return a;
    },
  },
};
</script>
<style lang="scss">
@import "./css/market-collect-container6.scss";
@import "./css/market-collect-container5.scss";
@import "./css/market-collect-container4.scss";
@import "./css/market-collect-container3.scss";
.none {
  padding-top: 1rem;
  text-align: center;
  font-size: 0.175rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1530a7;
  .el-image {
    width: 2.25rem;
    height: 1.25rem;
  }
}
.el-carousel__mask {
  background: transparent;
}
.collect_content {
  background: url(https://www.chuansmart.com/storage/screen/deal-icon.png)
    no-repeat;
  background-size: 320px 200px;
  width: 320px;
  height: 200px;
  position: relative;
  margin-top: 40px;
  .num {
    font-size: 38px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #41fe93;
    text-align: center;
    position: absolute;
    top: 0.625rem;
    width: 100%;
  }
  .text {
    font-size: 0.25rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    position: absolute;
    top: 1.125rem;
    width: 100%;
  }
}
.carousel-3d-slide {
  background: transparent !important;
  border: none !important;
}

// .carousel-3d-slide.right-2 {
//   .collect_content {
//     height: 1000px !important;
//     margin-top: -10px !important;
//   }
// }
// .carousel-3d-slide.left-2 {
//   .collect_content {
//     // height: 1000px !important;
//     margin-top: -10px !important;
//   }
// }
</style>
