<template>
  <div class="chart-container">
    <div
      class="chart"
      ref="chart"
      style="width:4rem;height: 3.4rem;z-index: 2; position: absolute;"
    ></div>
    <!-- 底座背景 -->
    <div class="bg"></div>
  </div>
</template>

<script>
import { getPie3D } from "@/utils/chart.js"; //工具类js，页面路径自己修改

const color = [
  "#3D6FF2",
  "#5A9AFF",
  "#3ED7FF",
  "#365DFE",
  "#6ECDFF",
  "#6EDBE6",
];

export default {
  name: "chart",
  props: {
    optionData: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      // optionData: [
      //   {
      //     name: "启用电梯",
      //     value: 25,
      //   },
      //   {
      //     name: "停用电梯",
      //     value: 25,
      //   },
      //   {
      //     name: "启用电梯",
      //     value: 14,
      //   },
      //   {
      //     name: "停用电梯",
      //     value: 13,
      //   },
      //   {
      //     name: "停用电梯",
      //     value: 12,
      //   },
      //   {
      //     name: "其他",
      //     value: 11,
      //   },
      // ],
      statusChart: null,
      option: {},
    };
  },
  created() {
    this.setLabel();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.optionData.length > 0) {
        this.initChart();
      }
    });

    //根据窗口变化自动调节图表大小
    const that = this;
    window.onresize = function() {
      that.changeSize();
    };
  },
  methods: {
    // 初始化label样式
    setLabel() {
      console.log(this.optionData, " this.optionData");
      this.optionData.forEach((item, index) => {
        item.itemStyle = {
          color: color[index],
        };
        item.label = {
          normal: {
            show: false,
            color: color[index],
            // formatter: ["{b|{b}}", "{c|{c}}{b|台}", "{d|{d}%}"].join(""),
            // 用来换行
            // rich: {
            //   b: {
            //     color: "#fff",
            //     lineHeight: 25,
            //     align: "left",
            //   },
            //   c: {
            //     fontSize: 22,
            //     color: "#fff",
            //     textShadowColor: "#1c90a6",
            //     textShadowOffsetX: 0,
            //     textShadowOffsetY: 2,
            //     textShadowBlur: 5,
            //   },
            //   d: {
            //     color: color[index],
            //     align: "left",
            //   },
            // },
          },
        };
        // item.labelLine = {
        //   normal: {
        //     lineStyle: {
        //       width: 1,
        //       color: "rgba(255,255,255,0.7)",
        //     },
        //   },
        // };
      });
    },
    // 图表初始化
    initChart() {
      this.statusChart = this.$echarts.init(this.$refs.chart);
      // 传入数据生成 option, 构建3d饼状图, 参数工具文件已经备注的很详细
      this.option = getPie3D(this.optionData, 0.85, 150, 20, 15, 1);
      this.statusChart.setOption(this.option);
      // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      this.option.series.push({
        name: "电梯状态", //自己根据场景修改
        backgroundColor: "transparent",
        type: "pie",
        label: {
          show: false,
          opacity: 1,
          fontSize: 13,
          lineHeight: 20,
        },
        startAngle: -40, // 起始角度，支持范围[0, 360]。
        clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ["20%", "50%"],
        center: ["50%", "50%"],
        data: this.optionData,
        itemStyle: {
          opacity: 0, //这里必须是0，不然2d的图会覆盖在表面
        },
      });
      this.statusChart.setOption(this.option);
    },
    // 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
    // optionName是防止有多个图表进行定向option传递，单个图表可以不传，默认是opiton

    // 自适应宽高
    changeSize() {
      this.statusChart.resize();
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  .chart {
    top: 0px;
    left: 3rem;
  }

  .chart,
  .bg {
    width: 100%;
    height: 100%;
  }

  .bg {
    position: absolute;
    top: 0.75rem;
    right: -0.1875rem;
    z-index: 1;
    width: 6rem;
    height: 3rem;
    background: no-repeat center;
    background-image: url("https://www.chuansmart.com/storage/screen/test-item-bg.png");
    background-size: 100% 100%;
    transform: rotateX(50deg);
  }
}
</style>
