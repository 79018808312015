<template>
  <div class="week-deal-container">
    <div class="title1">今日数据</div>
    <div class="line"></div>
    <div v-show="length != 0">
      <div
        id="today_echarts"
        style=" width: 7.725rem;
          height: 3.4rem;"
        ref="today_echarts"
      ></div>
    </div>
    <div v-show="length == 0" class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { todayData2Serve } from "./api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      length: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.iniChart();
    });
    this.timer = setInterval(() => {
      // 要执行的函数
      this.iniChart();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },

  methods: {
    async iniChart() {
      const { code, data } = await todayData2Serve({
        action: "sale.list",
      });
      var max_money = parseInt(
        Math.max(...data.map((c) => c.sales_money)) / 100
      );
      var max_num = parseInt(Math.max(...data.map((c) => c.flow_number)));

      var multiple = max_money / max_num;
      console.log(multiple, "multiple");
      this.length = data.length;
      if (code == 0 && data.length > 0) {
        // var myChart = this.$echarts.init(
        //   document.getElementById("today_echarts")
        // );
        var myChart = this.$echarts.init(this.$refs.today_echarts);

        // 指定图表的配置项和数据
        var option = {
          tooltip: {
            trigger: "item",
            axisPointer: {
              //坐标轴指示器
              type: "none", //十字准星指示器
            },
          },
          legend: {
            data: ["销售", "客流"],
            top: 30,
            left: "60%",
            textStyle: { fontSize: 14, color: "#ffffff" },
            // align: "left",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            data: data.map((item) => item.hour),
            axisLabel: {
              color: "#fff",
              textStyle: {
                fontSize: "18",
              },
            },
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#1F297B", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
          },
          yAxis: [
            {
              type: "value",
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                color: "#fff",
                textStyle: {
                  fontSize: "18",
                },
              },

              //轴线
              axisLine: {
                show: true,
                lineStyle: {
                  type: "solid",
                  color: "#1F297B", //左边线的颜色
                  width: "1", //坐标线的宽度
                },
              },
              //分割线
              splitLine: {
                show: false,
              },
            },
            {
              type: "value",
              axisLabel: {
                color: "#fff",
                textStyle: {
                  fontSize: "18",
                },
              },
              //轴线
              axisLine: {
                show: true,
                lineStyle: {
                  type: "solid",
                  color: "#1F297B", //左边线的颜色
                  width: "1", //坐标线的宽度
                },
              },

              //分割线
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "销售",
              type: "line",
              yAxisIndex: 0,
              data: data.map((item) => item.sales_money),
              smooth: true,
              symbol: "none",
              barWidth: "20%",
              lineStyle: {
                normal: {
                  color: "#244DB7", // 折线颜色
                },
              },
              itemStyle: {
                normal: {
                  color: "#244DB7", // 拐点颜色
                },
              },
              areaStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#244DB7",
                  },
                  {
                    offset: 1,
                    color: "#05004D",
                  },
                ]),
              },
            },
            {
              name: "客流",
              type: "line",
              itemStyle: {
                color: "#03F9FF",
              },
              smooth: true,
              symbol: "none",
              yAxisIndex: 1,

              //       // symbolSize: 8, // 拐点大小

              lineStyle: {
                normal: {
                  color: "#03F9FF", // 折线颜色
                },
              },
              areaStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#03F9FF",
                  },
                  {
                    offset: 1,
                    color: "#05004D",
                  },
                ]),
              },
              data: data.map((item) => item.flow_number),
            },
          ],
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.week-deal-container {
  background: url(https://www.chuansmart.com/storage/screen/div-bg.png)
    no-repeat;
  background-size: 7.725rem 3.975rem;
  width: 7.725rem;
  height: 3.975rem;
  margin-bottom: 0.1rem;
  .title1 {
    font-size: 0.25rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 0.15rem;
  }
  .line {
    width: 5.375rem;
    height: 0.025rem;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 0.15rem auto 0;
  }
  .deal-content {
    width: 3rem;
    height: 2rem;
    transition: all 1.5s;
    background: url(https://www.chuansmart.com/storage/screen/deal-icon.png)
      no-repeat;
    background-size: 3rem 2rem;
    position: relative;
    .num {
      font-size: 0.45rem;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #41fe93;
      text-align: center;
      position: absolute;
      top: 0.625rem;
      width: 100%;
    }
    .text {
      font-size: 0.175rem;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      position: absolute;
      top: 1.125rem;
      width: 100%;
    }
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 0.175rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 2.25rem;
      height: 1.25rem;
    }
  }
}
</style>
