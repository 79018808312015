<template>
  <div id="index1">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div
          class="d-flex jc-center"
          style="width:100%;height:1.25rem;margin-bottom:0.45rem;background-color:#000281;"
        >
          <div
            style="display:flex;justify-content:space-between;width:100%;height:0.9rem;align-items:center;padding:0 0.5rem;background-color:#0008B3;"
          >
            <div class="logo" style="display:flex;align-items:center;">
              <img
                src="../../assets/logo.png"
                style="width:0.5rem;height:0.5rem;margin-right:0.1rem;"
              />
              传蔬·科技服务民生
            </div>

            <div class="text">{{ dateYear }} {{ dateDay }} {{ dateWeek }}</div>
          </div>
          <div class="top_head">{{ market_name }}</div>
        </div>
        <div>
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            style="height:10.8rem;"
            v-if="merchantList.length > 0"
            class="swiper"
          >
            <swiper-slide
              v-for="(item, index) in merchantList"
              :key="index"
              class="info-content"
            >
              <div v-for="(c, d) in item.item" :key="d">
                <div class="info" v-if="c">
                  <div class="top">
                    <div class="left">
                      <img
                        :src="c.trading_certificate_image"
                        v-if="c.trading_certificate_image"
                      />
                    </div>
                    <div class="right">
                      <div class="name">
                        <div>
                          <img src="../../assets/merchantImg/name.png" />
                        </div>
                        <div>{{ c.merchant_name | ellipsis1 }}</div>
                      </div>
                      <div class="booth">
                        <div>
                          <img src="../../assets/merchantImg/shop.png" />
                        </div>
                        <div>{{ c.floor_code.join(";") | ellipsis }}</div>
                      </div>
                      <div class="booth">
                        <div>
                          <img src="../../assets/merchantImg/tel.png" />
                        </div>
                        <div>{{ c.tel }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <div>
                      <img
                        :src="c.business_certificate_image"
                        v-if="c.business_certificate_image"
                      />
                    </div>
                    <div>
                      <img :src="c.img_url" v-if="c.img_url" />
                    </div>
                    <div>
                      <img :src="c.other_image" v-if="c.other_image" />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div>
        <marquee
          v-if="global_data.is_show_notice == 1"
          direction="left"
          class="span1"
        >
          {{ global_data.notice }}
        </marquee>
        <div v-if="global_data.is_show_notice == 0" class="span">
          上海传蔬信息提供技术支持 电话：400-135-9365
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "../../utils/index.js";
// import Swiper from "swiper";
import "swiper/dist/css/swiper.css";

import { swiper, swiperSlide } from "vue-awesome-swiper";
// const c_swiper = document.getElementsByClassName("swiper");

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    // const _self = this;
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      global_data: {
        notice: "",
        is_show_notice:1
      }, //全局数据
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      market_name: "商户信息公示栏",
      merchantList: [],
      start: 0,
      // length: 12,
      total: 0,
      swiperOption: {
        loop: true, // 循环模式
        autoplay: {
          delay: 9000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // slidesPerView: 2,
        direction: "vertical",
        speed: 3000,
        spaceBetween: 24,
        on: {
          //  2. 在滚动事件中通过上面保存的swiper元素获取当前页索引,可以打印一下c_swiper看一下内部属性
          slideChange: () => {
            // if (this.$refs.mySwiper.swiper.realIndex == 1) {
            //   if (this.total != 0) {
            //     this.start = this.start + this.length;
            //     this.getMerchantList();
            //   } else {
            //     this.start = 0;
            //     this.getMerchantList();
            //   }
            // }
          },
        },
      },
      currentIndex: 0,
      uuid: "",
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 7) {
        return value.slice(0, 7) + "...";
      }
      return value;
    },
    ellipsis1(value) {
      if (!value) return "";
      if (value.length > 6) {
        return value.slice(0, 6);
      }
      return value;
    },
  },

  mounted() {
    this.init();
    // setInterval(() => {
    //   this.$router.go(0);
    // }, 1800000);
  },
  methods: {
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.timeFn();
      this.getMerchantList();
      this.getMarketTitle();
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },

    destroyed() {
      //离开页面是销毁
      clearInterval(this.timer);
      this.timer = null;
    },

    async getMerchantList() {
      const { data: resp } = await axios.post("api/screen/merchant/main", {
        action: "merchant.certificate.get",
        start: this.start,
        length: this.length,
        uuid: this.uuid,
      });
      const { code, data, total } = resp;
      if (code == 0) {
        this.total = total;
        // if (data.length > 6) {
        for (let i = 0; i < data.length; i += 6) {
          this.merchantList.push(data.slice(i, i + 6));
        }
        this.merchantList = this.merchantList.map((item, index) => {
          return {
            item:
              item.length != 6
                ? item.concat(this.merchantList[index - 1]).slice(0, 6)
                : item,
          };
        });
        this.loading = false;
      }
    },
    getMarketTitle() {
      axios
        .post("api/screen/client/main", {
          action: "market.common.get",
          type: this.type,
          uuid: this.uuid,
          // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
        })
        .then((res) => {
          let objData = res.data.data;
          this.global_data = objData;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #000281;
}
.top_head {
  position: fixed;
  top: 0;
  left: calc(50% - 5rem);
  width: 10rem;
  height: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  font-size: 0.65rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  color: #76fff4;
  background: url("../../assets/merchantImg/title_bg.png") no-repeat;
  background-size: 100% 1.25rem;
}
.logo {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.text {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: right;
}
.seamless-warp {
  height: 15rem;
  overflow: hidden;
}
.info-content {
  // display: grid;
  // justify-content: space-between;
  // grid-template-columns: repeat(auto-fill, 7.525rem);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto 10px;

  // justify-content: space-between;
  .info {
    background: url("../../assets/merchantImg/info_bg.png") no-repeat;
    background-size: 7.525rem 5.2rem;
    width: 7.525rem;
    height: 5.2rem;
    color: #fff;
    margin-left: 0.35rem;
    // padding: 20px 0;
    .top {
      display: flex;
      justify-content: space-around;
      margin-top: 0.25rem;
      .left {
        width: 3.625rem;
        height: 2.825rem;
        background: url("../../assets/merchantImg/certify_bg1.png") no-repeat;
        background-size: 3.625rem 2.825rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        img {
          width: 3.5rem;
          height: 2.625rem;
          border-radius: 12px;
        }
      }
      .right {
        width: 3.625rem;
        height: 2.825rem;
        background: url("../../assets/merchantImg/merchant_bg.png") no-repeat;
        background-size: 3.625rem 2.825rem;
        padding: 0.2rem 0;
        // padding: 0.1rem;
        .name {
          display: flex;
          align-items: center;
          background: rgba(97, 117, 255, 0.1);
          border-radius: 8px;

          padding: 10px 0;
          height: 0.7rem;
          width: 3.1rem;
          margin: 0 auto;
          overflow: hidden;

          img {
            width: 40px;
            height: 40px;
            margin-right: 4px;
          }
          font-size: 32px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #76fff4;
        }
        .booth {
          display: flex;
          align-items: center;
          background: rgba(97, 117, 255, 0.1);
          border-radius: 8px;

          padding: 12px 0;
          height: 0.7rem;
          width: 3.1rem;
          margin: 0.15rem auto 0;
          overflow: hidden;
          img {
            width: 40px;
            height: 40px;
            margin-right: 4px;
          }
          font-size: 32px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #76fff4;
          font-size: 28px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-around;
      margin-top: 0.125rem;
      div {
        background: url("../../assets/merchantImg/pic_bg.png") no-repeat;
        background-size: 2.3rem 1.775rem;
        width: 2.3rem;
        height: 1.775rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        img {
          width: 2.1rem;
          height: 1.575rem;
          border-radius: 12px;
        }
      }
    }
  }
}
.span {
  font-size: 24px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 26px;
  text-align: center;
}
.span1 {
  font-size: 24px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  height: 46px;
  padding-top: 20px;
  // border: 1px solid #fff;
}
</style>
