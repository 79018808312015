<!--
 * @Description:会动的词云
 * @Author: Vergil
 * @Date: 2021-08-25 14:17:45
 * @LastEditTime: 2021-08-25 17:08:15
 * @LastEditors: Vergil
-->
<template>
  <div class="cate-sales-container">
    <!-- 品类销售排行 -->
    <div class="title1">品类分布</div>
    <div class="line"></div>

    <div v-show="worddata.length != 0">
      <div id="mywordcloud" :data="worddata" ref="mywordcloud"></div>
    </div>
    <div v-show="hotWord.length == 0" class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { cateSalesServe } from "./api";
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min";
export default {
  name: "word-cloud",
  data() {
    return {
      hotWord: [],
      color: [
        "#00DCA9",
        "#6EC7FF",
        "#3451FF",
        "#2E528B",
        "#068A82",
        "#161C6A",
        "#253A70",
        "#202B89",
      ],
      wordArr: [],
      timer: null,
      resetTime: 10,
      ContainerSize: "",
      worddata: [],
    };
  },
  mounted() {
    this.getCateSales();
    this.timer1 = setInterval(() => {
      // 要执行的函数
      this.$nextTick(() => {
        // this.resetTime = 10;
        // this.time = null;
        // this.ContainerSize = "";
        // this.arr = [];
        // this.hotWord = [];
        // this.wordArr = [];
        // const wordArr = [];
        // console.log(this.hotWord, " this.hotWord");
        // this.hotWord.forEach((value) => {
        //   // 根据词云数量生成span数量设置字体颜色和大小
        //   const spanDom = document.createElement("span");
        //   spanDom.style.display = "none";
        //   this.$refs.wordCloud.removeChild();
        //   wordArr.push(spanDom);
        // });
        // this.wordArr = wordArr;
        this.getCateSales();
        // this.dealSpan();
        // this.initWordPos();
        // this.render();
      });
    }, 10000);
  },
  destoryed() {
    this.clearInterval(this.timer1);
  },
  methods: {
    async getCateSales() {
      const { code, data } = await cateSalesServe({
        action: "product.cate.list",
      });
      if (code == 0 && data.length > 0) {
        if (data.length < 20) {
          this.worddata = data
            .concat(data)
            .concat(data)
            .map((item) => {
              return {
                name: item.cate_name,
                value: item.order_num,
              };
            });
        } else {
          this.worddata = data.map((item) => {
            return {
              name: item.cate_name,
              value: item.order_num,
            };
          });
        }

        // this.initChart();
        this.getWordCloud();
        this.arr = data.map((item) => {
          return item.cate_name;
        });
        this.hotWord = this.arr;
        // this.hotWord =
        //   arr.concat(arr).length > 30
        //     ? arr.concat(arr).slice(0, 30)
        //     : arr.concat(arr);
      }
      this.$nextTick(() => {
        this.dealSpan();
        this.initWordPos();
        this.render();
      });
    },
    dealSpan() {
      const wordArr = [];
      console.log(this.hotWord, " this.hotWord");
      this.hotWord.forEach((value) => {
        // 根据词云数量生成span数量设置字体颜色和大小
        const spanDom = document.createElement("span");
        spanDom.style.position = "relative";
        spanDom.style.display = "inline-block";
        spanDom.style.color = this.randomColor();
        spanDom.style.fontSize = this.randomNumber(16, 40) + "px";
        spanDom.style.fontWeight = 400;

        spanDom.innerHTML = value;
        spanDom.local = {
          position: {
            // 位置
            x: 0,
            y: 0,
          },
          direction: {
            // 方向 正数往右 负数往左
            x: 1,
            y: 1,
          },
          velocity: {
            // 每次位移初速度
            x: -0.5 + Math.random(),
            y: -0.5 + Math.random(),
          },
        };
        wordArr.push(spanDom);
      });
      this.wordArr = wordArr;
    },
    randomColor() {
      // 获取随机颜色
      var colorIndex = Math.floor(this.color.length * Math.random());
      return this.color[colorIndex];
    },
    randomNumber(lowerInteger, upperInteger) {
      // 获得一个包含最小值和最大值之间的随机数。
      const choices = upperInteger - lowerInteger + 1;
      return Math.floor(Math.random() * choices + lowerInteger);
    },
    render() {
      if (this.resetTime < 100) {
        this.resetTime = this.resetTime + 1;
        this.timer = requestAnimationFrame(this.render.bind(this));
        this.resetTime = 0;
      }
      this.wordFly();
    },

    wordFly() {
      this.wordArr.forEach((value) => {
        // 设置运动方向 大于边界或者小于边界的时候换方向
        if (
          value.local.realPos.minx + value.local.position.x <
            this.ContainerSize.leftPos.x ||
          value.local.realPos.maxx + value.local.position.x >
            this.ContainerSize.rightPos.x
        )
          value.local.direction.x = -value.local.direction.x;
        if (
          value.local.realPos.miny + value.local.position.y <
            this.ContainerSize.leftPos.y ||
          value.local.realPos.maxy + value.local.position.y >
            this.ContainerSize.rightPos.y
        )
          value.local.direction.y = -value.local.direction.y;
        value.local.position.x +=
          value.local.velocity.x * value.local.direction.x;
        value.local.position.y +=
          value.local.velocity.y * value.local.direction.y;
        // 给每个词云加动画过渡
        value.style.transform =
          "translateX(" +
          value.local.position.x +
          "px) translateY(" +
          value.local.position.y +
          "px)";
      });
    },
    initWordPos() {
      // 计算每个词的真实位置和容器的位置
      this.wordArr.forEach((value) => {
        value.local.realPos = {
          minx: value.offsetLeft,
          maxx: value.offsetLeft + value.offsetWidth,
          miny: value.offsetTop,
          maxy: value.offsetTop + value.offsetHeight,
        };
      });
      this.ContainerSize = this.getContainerSize();
    },
    getContainerSize() {
      // 判断容器大小控制词云位置
      const el = this.$refs.mywordcloud;
      return {
        leftPos: {
          // 容器左侧的位置和顶部位置
          x: el.offsetLeft,
          y: el.offsetTop,
        },
        rightPos: {
          // 容器右侧的位置和底部位置
          x: el.offsetLeft + el.offsetWidth,
          y: el.offsetTop + el.offsetHeight,
        },
      };
    },
    initChart() {
      this.chart = this.$echarts.init(document.getElementById("mywordcloud"));
      const colorList = [
        "#00DCA9",
        "#6EC7FF",
        "#3451FF",
        "#2E528B",
        "#068A82",
        "#161C6A",
        "#253A70",
        "#202B89",
      ];
      console.log("lll");
      const option = {
        // title: {
        //   text: "热爱祖国",
        //   x: "center",
        // },
        // backgroundColor: "#fff",
        // tooltip: {
        //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
        // },
        series: [
          {
            type: "wordCloud",
            //用来调整词之间的距离
            // gridSize: 10,
            //用来调整字的大小范围
            // Text size range which the value in data will be mapped to.
            // Default to have minimum 12px and maximum 60px size.
            sizeRange: [30, 60],
            // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
            //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
            // rotationRange: [-45, 0, 45, 90],
            // rotationRange: [ 0,90],
            rotationRange: [-45, 90],
            rotationStep: 45,
            gridSize: 8,
            //随机生成字体颜色
            // maskImage: maskImage,
            textStyle: {
              normal: {
                // color: function() {
                //   let index = Math.floor(Math.random() * colorList.length);
                //   return colorList[index];
                // },
                color: function() {
                  return (
                    "rgb(" +
                    [
                      Math.round(Math.random() * 250),
                      Math.round(Math.random() * 250),
                      Math.round(Math.random() * 250),
                    ].join(",") +
                    ")"
                  );
                },
              },
            },
            //位置相关设置
            // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
            // Default to be put in the center and has 75% x 80% size.
            left: "center",
            top: "center",
            width: "80%",
            height: "80%",
            right: null,
            bottom: null,
            //数据
            data: this.worddata,
          },
        ],
      };
      this.chart.setOption(option);
    },
    getWordCloud() {
      // var accessToElements = document.getElementById("mywordcloud"); //绑定元素
      var accessToElements = this.$refs.mywordcloud;
      var themeStyle = this.$echarts.init(accessToElements);
      //图表自适应配置
      const chartNode = new ResizeObserver(() => {
        themeStyle.resize();
      });
      const colorList = [
        "#00DCA9",
        "#6EC7FF",
        "#3451FF",
        "#2E528B",
        "#068A82",
        "#161C6A",
        "#253A70",
        "#202B89",
      ];
      chartNode.observe(accessToElements);
      // 绘制图表
      var option = {
        series: [
          {
            type: "wordCloud",

            //要绘制的“云”的形状。任意极坐标方程都可以表示为a吗
            //回调函数，或关键字存在。可用的礼物为圆形(默认)，
            //心形(苹果或心形曲线，最著名的极坐标方程)，菱形(
            // alias of square)， triangle-forward, triangle， (alias of triangle- standing, pentagon, and star)

            shape: "star , triangle",
            // shape: "circle",

            //保持maskImage或1:1的形状的纵横比
            // echarts-wordcloud@2.1.0支持该选项
            keepAspect: false,

            //一个轮廓图像，白色区域将被排除在绘制文本之外。
            //当云的形状增长时，形状选项将继续应用。
            // maskImage: maskImage,

            //跟随左/顶/宽/高/右/底是用来定位字云
            //默认放置在中心，大小为75% x 80%。

            left: "center",
            top: null,
            width: "80%",
            height: "80%",
            right: null,
            bottom: null,

            //文本大小范围，数据中的值将被映射到。
            //默认最小12px，最大60px大小。

            sizeRange: [20, 60],

            //文本旋转范围和步进度。文本将被rotationStep 45在[- 90,90]范围内随机旋转

            rotationRange: [-45, 90],
            rotationStep: 45,

            //网格的大小(以像素为单位)，用于标记画布的可用性
            //网格大小越大，单词之间的间距越大。

            gridSize: 30,

            //设置为true允许文字部分绘制在画布外。
            //允许文字大于画布的大小
            drawOutOfBound: false,

            //如果执行布局动画。
            //注意当有很多字的时候禁用它会导致UI阻塞。
            layoutAnimation: true,

            // Global text style
            textStyle: {
              normal: {
                fontFamily: "sans-serif",
                color: function() {
                  let index = Math.floor(Math.random() * colorList.length);
                  return colorList[index];
                },
                // color: function() {
                //   return (
                //     "rgb(" +
                //     [
                //       Math.round(Math.random() * 250),
                //       Math.round(Math.random() * 250),
                //       Math.round(Math.random() * 250),
                //     ].join(",") +
                //     ")"
                //   );
                // },
              },
            },
            emphasis: {
              focus: "self",
            },

            // 内容，
            data: this.worddata,
          },
        ],
      };
      option && themeStyle.setOption(option);
    },
  },
  destroyed() {
    // 组件销毁，关闭定时执行
    cancelAnimationFrame(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.cate-sales-container {
  background: url(https://www.chuansmart.com/storage/screen/div-bg.png)
    no-repeat;
  background-size: 7.725rem 3.975rem;
  width: 7.725rem;
  height: 3.975rem;
  margin-bottom: 0.1rem;
  .title1 {
    font-size: 0.25rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 0.15rem;
  }
  .line {
    width: 5.375rem;
    height: 0.025rem;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 0.15rem auto 0;
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 0.175rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 2.25rem;
      height: 1.25rem;
    }
  }
}
#mywordcloud {
  margin: 0 auto;
  width: 90%;
  height: 250.025rem;
  margin-top: 20px;
  overflow: hidden;
}
</style>
