import { render, staticRenderFns } from "./areaSales.vue?vue&type=template&id=e1170288&"
import script from "./areaSales.vue?vue&type=script&lang=js&"
export * from "./areaSales.vue?vue&type=script&lang=js&"
import style0 from "./areaSales.vue?vue&type=style&index=0&id=e1170288&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports