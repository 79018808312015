<template>
  <div class="special-testing-container">
    <div class="title1">
      {{
        areaData == 1
          ? "质检台账"
          : areaData == 2
          ? "进货台账"
          : areaData == 3
          ? "消杀台账"
          : areaData == 4
          ? "消毒台账"
          : "设备检修台账"
      }}
    </div>
    <div class="line"></div>

    <div v-if="config.data.length != 0">
      <div class="content">
        <dv-scroll-board :config="config" />
      </div>
    </div>
    <div v-else class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { standingBook2Serve } from "./api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    areaData: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      config: {
        header: [],
        data: [],
        oddRowBGC: "#101349",
        evenRowBGC: "#111753",
      },
    };
  },

  computed: {},
  watch: {},
  mounted() {
    this.getQualityList();
    this.timer = setInterval(() => {
      // 要执行的函数
      this.getQualityList();
    }, 900000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  filters: {
    filterDate(num) {
      return moment.unix(num).format("YYYY-MM-DD");
    },
  },
  methods: {
    async getQualityList() {
      const { code, data } = await standingBook2Serve({
        action: "standing.get",
        type: this.areaData,
      });
      if (code == 0 && data.length > 0) {
        const target = data.map((c) => {
          const { value1, value2, value3, value4 } = c;
          return [
            '<span style="font-size:0.25rem">' +
              moment.unix(value1).format("YYYY-MM-DD") +
              "</span>",
            '<span style="font-size:0.2rem">' + value2 + "</span>",
            '<span style="font-size:0.25rem">' + value3 + "</span>",
            '<span style="font-size:0.25rem">' + value4 + "</span>",
          ];
        });
        this.config.data = target;
        console.log(this.config);
        this.config = {
          data: target,
          oddRowBGC: "#101349",
          evenRowBGC: "#111753",
          header:
            this.areaData == 1
              ? ["时间", "进场品名", "检测项目", "检测结果"]
              : this.areaData == 2
              ? ["时间", "进场品名", "商户", "进场数量"]
              : this.areaData == 3
              ? ["时间", "消杀区域", "消杀方式", "操作人"]
              : this.areaData == 4
              ? ["时间", "消杀区域", "消杀方式", "操作人"]
              : ["时间", "设备号", "商户", "设备厂家"],
          // header: ["时间", "商户", "铺位号", "交易金额"],
          // // index: true,
          // headerHeight: this.FontChart(0.4),
          // headerBGC: "rgb(4, 7, 138, 0)", //表头
          // oddRowBGC: "rgb(4, 7, 138, 0)", //奇数行
          // evenRowBGC: "rgb(4, 7, 138, 0)", //偶数行
          // columnWidth: [100],
          align: ["center", "center", "center", "center", "center"],
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.special-testing-container {
  margin-bottom: 0.1rem;
  .title1 {
    font-size: 0.25rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 0.15rem;
  }
  .line {
    width: 5.375rem;
    height: 0.025rem;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 0.15rem auto 0;
  }
  .content {
    padding: 0.0875rem 0.3125rem 0;
    overflow: hidden;
    height: 3.33rem;
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 0.175rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 2.25rem;
      height: 1.25rem;
    }
  }
}
</style>
<style lang="scss">
.special-testing-container {
  .dv-scroll-board .header {
    // opacity: 0 !important;
    background: #16174a;
    border: 0 !important;
  }
  .dv-scroll-board .header .header-item {
    font-size: 0.2rem !important;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
    font-weight: 500 !important;
    color: #94fffc !important;
    width: 100% !important;
  }
  .dv-scroll-board .rows .ceil {
    padding: 0;
    font-size: 0.175rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
  }
  .dv-scroll-board .rows .row-item {
    height: 0.5rem;
    line-height: 0.5rem;
  }
}
</style>
