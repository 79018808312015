<template>
  <div class="sales-rank-container">
    <div class="title1">商品排行</div>
    <div class="line"></div>

    <div v-if="config.data.length != 0">
      <div class="content">
        <dv-scroll-ranking-board :config="config" />
      </div>
    </div>
    <div v-else class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { productRank2Serve } from "./api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      config: {
        waitTime: 2000,
        data: [],
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getSalesRank();
    this.timer = setInterval(() => {
      // 要执行的函数
      this.getSalesRank();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async getSalesRank() {
      const { code, data } = await productRank2Serve({
        action: "product.get",
        limit: 10,
      });
      if (code == 0 && data.length > 0) {
        this.config = {
          ...this.config,
          data: data.map((item) => {
            return {
              name: item.product_name,
              value: item.order_num,
            };
          }),
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sales-rank-container {
  .title1 {
    font-size: 0.25rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 0.15rem;
  }
  .line {
    width: 5.375rem;
    height: 0.025rem;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 0.15rem auto 0;
  }
  .content {
    padding: 0.0875rem 0.3125rem 0;
    overflow: hidden;
    height: 3.33rem;
  }
}
</style>
<style lang="scss">
.sales-rank-container {
  .dv-scroll-ranking-board .row-item:nth-child(1) {
    .ranking-column .inside-column {
      background: linear-gradient(90deg, #d02c24 0%, #f47979 100%);
      border-radius: 10px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(2) {
    .ranking-column .inside-column {
      background: linear-gradient(270deg, #ffcc87 0%, #ff7629 100%);
      border-radius: 10px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(3) {
    .ranking-column .inside-column {
      background: linear-gradient(90deg, #fad53a 0%, #f9eaa3 100%);
      border-radius: 10px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(4) {
    .ranking-column .inside-column {
      background: linear-gradient(90deg, #5dc067 0%, #b6f2c1 100%);
      border-radius: 10px;
    }
  }
  .dv-scroll-ranking-board .row-item:nth-child(5) {
    .ranking-column .inside-column {
      background: linear-gradient(270deg, #7ce3ff 0%, #2133e7 100%);
      border-radius: 10px;
    }
  }
  //   .dv-scroll-ranking-board .row-item:nth-child(4) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #5dc067 0%, #b6f2c1 100%);
  //       border-radius: 10px;
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(5) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(270deg, #7ce3ff 0%, #2133e7 100%);
  //       border-radius: 10px;
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(6) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #d02c24 0%, #f47979 100%);
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(6) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #d02c24 0%, #f47979 100%);
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(7) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(270deg, #ffcc87 0%, #ff7629 100%);
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(8) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #fad53a 0%, #f9eaa3 100%);
  //       border-radius: 10px;
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(9) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(90deg, #5dc067 0%, #b6f2c1 100%);
  //       border-radius: 10px;
  //     }
  //   }
  //   .dv-scroll-ranking-board .row-item:nth-child(10) {
  //     .ranking-column .inside-column {
  //       background: linear-gradient(270deg, #7ce3ff 0%, #2133e7 100%);
  //       border-radius: 10px;
  //     }
  //   }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 0.175rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 2.25rem;
      height: 1.25rem;
    }
  }
}
</style>
