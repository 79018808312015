<template>
 
        <div class="new-screen-bg">
          <div class="title">
            <div class="le">
              {{ weather_info.city }}
              {{ weather_info.degree }}
              {{ weather_info.weather + " |" }}
              {{ weather_info.wind_direction }}
            </div>
            <div class="cen">
              {{ screen2Info.title }}
            </div>
            <div class="ri">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</div>
          </div>
          <div class="content">
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area1_time"
                @change="
                  (value) => area1Change(value, screen2Info.area1.area_list)
                "
                :autoplay="screen2Info.area1.is_roll == 1"
                :initial-index="0"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area1.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area1_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area1_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area1_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area1_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area1_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area1_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area1_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area1_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area1.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area1_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area1_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area1_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area1_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area1_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area1_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area1_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area1_index == index"
                  />
                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area1_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area1_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area1_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area1_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area1_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area1_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area2_time"
                @change="
                  (value) => area2Change(value, screen2Info.area2.area_list)
                "
                :autoplay="screen2Info.area2.is_roll == 1"
                :initial-index="0"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area2.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area2_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area2_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area2_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area2_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area2_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area2_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area2_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area2_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area2.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area2_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area2_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area2_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area2_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area2_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area2_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area2_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area2_index == index"
                  />
                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area2_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area2_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area2_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area2_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area2_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area2_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area3_time"
                @change="
                  (value) => area3Change(value, screen2Info.area3.area_list)
                "
                :autoplay="screen2Info.area3.is_roll == 1"
                :initial-index="0"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area3.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area3_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area3_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area3_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area3_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area3_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area3_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area3_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area3_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area3.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area3_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area3_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area3_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area3_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area3_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area3_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area3_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area3_index == index"
                  />
                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area3_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area3_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area3_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area3_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area3_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area3_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area4_time"
                @change="
                  (value) => area4Change(value, screen2Info.area4.area_list)
                "
                :autoplay="screen2Info.area4.is_roll == 1"
                :initial-index="0"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area4.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area4_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area4_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area4_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area4_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area4_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area4_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area4_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area4_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area4.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area4_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area4_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area4_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area4_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area4_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area4_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area4_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area4_index == index"
                  />
                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area4_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area4_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area4_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area4_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area4_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area4_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area4_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area4_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area4_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area5_time"
                @change="
                  (value) => area5Change(value, screen2Info.area5.area_list)
                "
                :autoplay="screen2Info.area5.is_roll == 1"
                :initial-index="0"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area5.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area5_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area5_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area5_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area5_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area5_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area5_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area5_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area5_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area5.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area5_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area5_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area5_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area5_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area5_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area5_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area5_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area5_index == index"
                  />
                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area5_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area5_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area5_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area5_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area5_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area5_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area6_time"
                @change="
                  (value) => area6Change(value, screen2Info.area6.area_list)
                "
                :autoplay="screen2Info.area6.is_roll == 1"
                :initial-index="0"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area6.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area6_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area6_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area6_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area6_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area6_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area6_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area6_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area6_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area6.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area6_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area6_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area6_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area6_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area6_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area6_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area6_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area6_index == index"
                  />
                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area6_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area6_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area6_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area6_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area6_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area6_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area7_time"
                @change="
                  (value) => area7Change(value, screen2Info.area7.area_list)
                "
                :initial-index="0"
                :autoplay="screen2Info.area7.is_roll == 1"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area7.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area7_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area7_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area7_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area7_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area7_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area7_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area7_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area7_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area7.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area7_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area7_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area7_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area7_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area7_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area7_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area7_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area7_index == index"
                  />

                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area7_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area7_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area7_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area7_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area7_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area7_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area8_time"
                @change="
                  (value) => area8Change(value, screen2Info.area8.area_list)
                "
                :autoplay="screen2Info.area8.is_roll == 1"
                :initial-index="0"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area8.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area8_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area8_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area8_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area8_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area8_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area8_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area8_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area8_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area8.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area8_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area8_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area8_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area8_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area8_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area8_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area8_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area8_index == index"
                  />
                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area8_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area8_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area8_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area8_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area8_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area8_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="box">
              <el-carousel
                indicator-position="none"
                :interval="area9_time"
                @change="
                  (value) => area9Change(value, screen2Info.area9.area_list)
                "
                :autoplay="screen2Info.area9.is_roll == 1"
                :initial-index="0"
              >
                <el-carousel-item
                  v-for="(item, index) in screen2Info.area9.area_list"
                  :key="index"
                >
                  <!-- 销售图表 -->
                  <todayData
                    ref="todayData"
                    v-if="item.resource == 1 && area9_index == index"
                  />
                  <!-- 市场汇总 -->
                  <marketCollect
                    ref="marketCollect"
                    v-if="item.resource == 2 && area9_index == index"
                    :areaData="item.relation"
                  />
                  <!-- 质检公示 -->
                  <!--  -->

                  <qualityTesting
                    ref="qualityTesting"
                    v-if="item.resource == 3 && area9_index == index"
                  />
                  <areaProportion
                    ref="areaProportion"
                    v-if="item.resource == 4 && area9_index == index"
                  />
                  <testItem
                    ref="testItem"
                    :areaData="item.relation"
                    v-if="item.resource == 5 && area9_index == index"
                  />

                  <specialPrice
                    ref="specialPrice"
                    v-if="item.resource == 6 && area9_index == index"
                  />
                  <!-- v-if="item.type == 7" -->
                  <imageData
                    ref="imageData"
                    :areaData="item.relation"
                    v-if="item.resource == 7 && area9_index == index"
                  />
                  <videoData
                    ref="videoData"
                    v-if="item.resource == 8 && area9_index == index"
                    :areaData="item.relation"
                    :is_roll="screen2Info.area9.is_roll == 1 ? true : false"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 1 &&
                        area9_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 2 &&
                        area9_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 3 &&
                        area9_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 4 &&
                        area9_index == index
                    "
                    :areaData="item.relation"
                  />
                  <standingBook
                    ref="standingBook"
                    v-if="
                      item.resource == 9 &&
                        item.relation == 5 &&
                        area9_index == index
                    "
                    :areaData="item.relation"
                  />
                  <areaSales
                    ref="areaSales"
                    v-if="item.resource == 10 && area9_index == index"
                  />
                  <!--  -->

                  <productRank
                    ref="productRank"
                    v-if="item.resource == 11 && area9_index == index"
                  />
                  <cateSales
                    ref="cateSales"
                    v-if="item.resource == 12 && area9_index == index"
                  />
                  <salesRank
                    ref="salesRank"
                    v-if="item.resource == 13 && area9_index == index"
                  />
                  <realTrade
                    ref="realTrade"
                    v-if="item.resource == 14 && area9_index == index"
                  />

                  <dayDeal
                    ref="dayDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 1 &&
                        area9_index == index
                    "
                    :areaData="item.relation"
                  />
                  <weekDeal
                    ref="weekDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 2 &&
                        area9_index == index
                    "
                    :areaData="item.relation"
                  />
                  <monthDeal
                    ref="monthDeal"
                    v-if="
                      item.resource == 15 &&
                        item.relation == 3 &&
                        area9_index == index
                    "
                    :areaData="item.relation"
                  />
                  <startMerchant
                    ref="startMerchant"
                    v-if="item.resource == 16 && area9_index == index"
                  />
                  <passengerNumDay
                    ref="passengerNumDay"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 1
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumWeek
                    ref="passengerNumWeek"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 2
                    "
                    :areaData="item.relation"
                  />
                  <passengerNumMonth
                    ref="passengerNumMonth"
                    v-if="
                      item.resource == 17 &&
                        area1_index == index &&
                        item.relation == 3
                    "
                    :areaData="item.relation"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div>
            <!-- <marquee
        v-if="screen2Info.is_show_notice == 1"
        direction="left"
        class="notic"
      > -->
            <div class="notic" v-if="screen2Info.is_show_notice == 1">
              <marquee
                v-if="screen2Info.is_show_notice == 1"
                direction="left"
              >
                {{ screen2Info.notice }}
              </marquee>
            </div>

            <!-- </marquee> -->
            <div v-else class="notic">
              上海传蔬信息提供技术支持 电话：400-135-9365
            </div>
          </div>
        </div>
     
</template>
<script>
import areaProportion from "./components/areaProportion.vue";
import marketCollect from "./components/marketCollect.vue";
import weekDeal from "./components/weekDeal.vue";
import areaSales from "./components/areaSales.vue";
import todayData from "./components/todayData.vue";
import passengerNumDay from "./components/passengerNumDay.vue";
import testItem from "./components/testItem.vue";
import startMerchant from "./components/startMerchant.vue";
import cateSales from "./components/cateSales.vue";
import qualityTesting from "./components/qualityTesting.vue";
import specialPrice from "./components/specialPrice.vue";
import imageData from "./components/imageData.vue";
import videoData from "./components/videoData.vue";
import standingBook from "./components/standingBook.vue";
import salesRank from "./components/salesRank.vue";
import dayDeal from "./components/dayDeal.vue";
import monthDeal from "./components/monthDeal.vue";
import productRank from "./components/productRank.vue";
import realTrade from "./components/realTrade.vue";
import passengerNumWeek from "./components/passengerNumWeek.vue";
import passengerNumMonth from "./components/passengerNumMonth.vue";
import { screenTemplate2Serve, screenWeather2Serve } from "./api";
import { formatTime } from "@/utils/index.js";

export default {
  name: "",
  components: {
    areaProportion,
    marketCollect,
    weekDeal,
    areaSales,
    todayData,
    passengerNumDay,
    testItem,
    startMerchant,
    cateSales,
    qualityTesting,
    specialPrice,
    imageData,
    videoData,
    standingBook,
    salesRank,
    dayDeal,
    monthDeal,
    productRank,
    realTrade,
    passengerNumWeek,
    passengerNumMonth,
  },
  mixins: [],
  props: {},
  data() {
    return {
      screen2Info: {
        is_show_notice:1,
        area1: {
          area_list: [],
        },
        area2: {
          area_list: [],
        },
        area3: {
          area_list: [],
        },
        area4: {
          area_list: [],
        },
        area5: {
          area_list: [],
        },
        area6: {
          area_list: [],
        },
        area7: {
          area_list: [],
        },
        area8: {
          area_list: [],
        },
        area9: {
          area_list: [],
        },
      },

      type1: true,
      type2: false,
      area1_time: 5000,
      area1_index: 0,
      area2_index: 0,
      area2_time: 5000,
      area3_index: 0,
      area3_time: 5000,
      area4_index: 0,
      area4_time: 5000,
      area5_index: 0,
      area5_time: 5000,
      area6_index: 0,
      area6_time: 5000,
      area7_index: 0,
      area7_time: 5000,
      area8_index: 0,
      area8_time: 5000,
      area9_index: 0,
      area9_time: 5000,
      dateDay: "",
      dateYear: "",
      dateWeek: "",
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      ip: "",
      weather_info: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getScreen2Info();
    this.getLocalCity();

    // const getIPs = (callback) => {
    //   var ip_dups = {};
    //   var RTCPeerConnection =
    //     window.RTCPeerConnection ||
    //     window.mozRTCPeerConnection ||
    //     window.webkitRTCPeerConnection;
    //   var useWebKit = !!window.webkitRTCPeerConnection;
    //   var mediaConstraints = {
    //     optional: [{ RtpDataChannels: true }],
    //   };
    //   // 这里就是需要的ICEServer了
    //   var servers = {
    //     iceServers: [
    //       { urls: "stun:stun.services.mozilla.com" },
    //       { urls: "stun:stun.l.google.com:19302" },
    //     ],
    //   };
    //   var pc = new RTCPeerConnection(servers, mediaConstraints);
    //   function handleCandidate(candidate) {
    //     var ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
    //     var hasIp = ip_regex.exec(candidate);
    //     if (hasIp) {
    //       var ip_addr = ip_regex.exec(candidate)[1];
    //       if (ip_dups[ip_addr] === undefined) callback(ip_addr);
    //       ip_dups[ip_addr] = true;
    //     }
    //   }
    //   // 网络协商的过程
    //   pc.onicecandidate = function(ice) {
    //     if (ice.candidate) {
    //       handleCandidate(ice.candidate.candidate);
    //     }
    //   };
    //   pc.createDataChannel("");
    //   //创建一个SDP(session description protocol)会话描述协议 是一个纯文本信息 包含了媒体和网络协商的信息
    //   pc.createOffer(
    //     function(result) {
    //       pc.setLocalDescription(
    //         result,
    //         function() {},
    //         function() {}
    //       );
    //     },
    //     function() {}
    //   );
    //   setTimeout(function() {
    //     var lines = pc.localDescription.sdp.split("\n");
    //     lines.forEach(function(line) {
    //       if (line.indexOf("a=candidate:") === 0) handleCandidate(line);
    //     });
    //   }, 1000);
    // };
    // let that = this;
    // getIPs((ip) => {
    //   console.log(ip);
    //   that.ip = ip;
    //   console.log(that.ip);
    //   that.getScreenWeather();
    // });
    clearInterval(this.timeFns);
    this.timeFns = setInterval(() => {
      this.dateDay = formatTime(new Date(), "HH: mm: ss");
      this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
      this.dateWeek = this.weekday[new Date().getDay()];
    }, 1000);
    this.timer = setInterval(() => {
      this.getScreen2Info();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    getLocalCity() {
      var data = {
        key: "72IBZ-SVTRQ-QPR5H-GSIUJ-T3ZOO-AZF2H", //申请的密钥,写死就行
      };
      var url = "https://apis.map.qq.com/ws/location/v1/ip"; //这个就是地理位置信息的接口
      data.output = "jsonp";
      this.$jsonp(url, data)
        .then((res) => {
          console.log(res, "1111111111111");
          this.ip = res.result.ip;
          if (this.ip) {
            this.getScreenWeather();
          }
        })
        .catch(() => {});
    },
    area1Change(val, val1) {
      console.log(val, val1);
      this.area1_index = val;
      this.area1_time = this.screen2Info.area1.area_list[val].roll_time * 1000;
    },
    area2Change(val, val1) {
      console.log(val, val1);
      this.area2_index = val;
      this.area2_time = this.screen2Info.area2.area_list[val].roll_time * 1000;
    },
    area3Change(val, val1) {
      console.log(val, val1);
      this.area3_index = val;
      this.area3_time = this.screen2Info.area3.area_list[val].roll_time * 1000;
    },
    area4Change(val, val1) {
      console.log(val, val1);
      this.area4_index = val;
      this.area4_time = this.screen2Info.area4.area_list[val].roll_time * 1000;
    },
    area5Change(val, val1) {
      console.log(val, val1);
      this.area5_index = val;
      this.area5_time = this.screen2Info.area5.area_list[val].roll_time * 1000;
    },
    area6Change(val, val1) {
      console.log(val, val1);
      this.area6_index = val;
      this.area6_time = this.screen2Info.area6.area_list[val].roll_time * 1000;
    },
    area7Change(val, val1) {
      console.log(val, val1);
      this.area7_index = val;
      this.area7_time = this.screen2Info.area7.area_list[val].roll_time * 1000;
    },
    area8Change(val, val1) {
      console.log(val, val1);
      this.area8_index = val;
      this.area8_time = this.screen2Info.area8.area_list[val].roll_time * 1000;
    },
    area9Change(val, val1) {
      console.log(val, val1);
      this.area9_index = val;
      this.area9_time = this.screen2Info.area9.area_list[val].roll_time * 1000;
    },
    async getScreen2Info() {
      const { code, data } = await screenTemplate2Serve({
        action: "market.screen.template.second.get",
        type: this.$route.query.type,
      });
      if (code == 0) {
        this.screen2Info = data;
        this.area1_time = data.area1.area_list[0].roll_time * 1000;
        this.area2_time = data.area2.area_list[0].roll_time * 1000;
        this.area3_time = data.area3.area_list[0].roll_time * 1000;
        this.area4_time = data.area4.area_list[0].roll_time * 1000;
        this.area5_time = data.area5.area_list[0].roll_time * 1000;
        this.area6_time = data.area6.area_list[0].roll_time * 1000;
        this.area7_time = data.area7.area_list[0].roll_time * 1000;
        this.area8_time = data.area8.area_list[0].roll_time * 1000;
        this.area9_time = data.area9.area_list[0].roll_time * 1000;
      }
    },
    async getScreenWeather() {
      const { code, data } = await screenWeather2Serve({
        action: "weather.get",
        ip: this.ip,
      });
      if (code == 0) {
        this.weather_info = data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.new-screen-bg {
  //   background: #0b0c50;
  background: url(https://www.chuansmart.com/storage/screen/new-screen-bg.png)
    no-repeat;
  background-size: 24rem 13.5rem;
  width: 24rem;
  height: 13.5rem;
  .title {
    display: flex;
    justify-content: space-between;
    background: url(https://www.chuansmart.com/storage/screen/title-bar-bg.png)
      no-repeat;
    background-size: 24rem 0.85rem;
    height: 0.85rem;
    line-height: 0.85rem;
    .cen {
      font-size: 0.45rem;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #94fffc;
    }
    .le,
    .ri {
      font-size: 0.25rem;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      padding-right: 0.3rem;
      padding-left: 0.3rem;
      width: 3.9rem;
    }
  }
  .content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0.15rem 0.15rem 0 0.15rem;

    .box {
      background: url(https://www.chuansmart.com/storage/screen/div-bg.png)
        no-repeat;
      background-size: 7.725rem 3.975rem;
      width: 7.725rem;
      height: 3.975rem;
      margin-bottom: 0.1px;
      // overflow: hidden;
    }
  }
  .notic {
    // height:0.3rem;
    line-height:0.6rem;
    font-size: 0.175rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
}
</style>
<style lang="scss">
.new-screen-bg {
  .el-carousel__container {
    height: 3.975rem;
  }
}
</style>
