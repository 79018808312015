<template>
  <div class="quality-testing-container">
    <div class="title1">质检公示</div>
    <div class="line"></div>

    <div v-if="config.data.length != 0">
      <div class="content">
        <dv-scroll-board :config="config" />
      </div>
    </div>
    <div v-else class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { quality2Serve } from "./api";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      config: {
        header: ["商户", "铺位号", "品名", "检测项目", "结果"],
        data: [],
        oddRowBGC: "#101349",
        evenRowBGC: "#111753",
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getQualityList();
    this.timer = setInterval(() => {
      // 要执行的函数
      this.getQualityList();
    }, 900000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async getQualityList() {
      const { code, data } = await quality2Serve({
        action: "quality.list",
      });
      if (code == 0 && data.data.length > 0) {
        const arr = data.data.map((item) => {
          return {
            ...item,
            is_qualified: item.is_qualified == 1 ? "合格" : "不合格",
          };
        });
        const target = arr.map((c) => {
          const {
            orgz_name,
            floor_code,
            product_name,
            c_name,
            is_qualified,
          } = c;
          return [
            '<span style="font-size:0.25rem">' + orgz_name + "</span>",
            '<span style="font-size:0.25rem">' + floor_code + "</span>",
            '<span style="font-size:0.25rem">' + product_name + "</span>",
            '<span style="font-size:0.25rem">' + c_name + "</span>",
            '<span style="font-size:0.25rem">' + is_qualified + "</span>",
          ];
        });
        this.config.data = target;
        this.config = {
          data: target,
          oddRowBGC: "#101349",
          evenRowBGC: "#111753",
          header: ["商户", "铺位号", "品名", "检测项目", "结果"],

          align: ["center", "center", "center", "center", "center"],
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.quality-testing-container {
  margin-bottom: 0.1rem;
  .title1 {
    font-size: 0.25rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 0.15rem;
  }
  .line {
    width: 5.375rem;
    height: 0.025rem;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 0.15rem auto 0;
  }
  .content {
    padding: 0.0875rem 0.3125rem 0;
    overflow: hidden;
    height: 3.33rem;
  }
}
</style>
<style lang="scss">
.dv-scroll-board .header {
  // opacity: 0 !important;
  background: #16174a !important;
  border: 0 !important;
}
.dv-scroll-board .header .header-item {
  font-size: 0.2rem !important;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
  font-weight: 500 !important;
  color: #94fffc !important;
  width: 100% !important;
}
.dv-scroll-board .rows .ceil {
  padding: 0;
  font-size: 0.175rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
}
.dv-scroll-board .rows .row-item {
  height: 40px;
  line-height: 40px;
}
.none {
  padding-top: 1rem;
  text-align: center;
  font-size: 0.175rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1530a7;
  .el-image {
    width: 2.25rem;
    height: 1.25rem;
  }
}
</style>
