<template>
  <div class="video-data-container">
    <div v-if="areaData != ''">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        id="myvideo"
        @timeupdate="onPlayerTimeupdate($event)"
        @ended="onPlayerEnded($event)"
      ></video-player>
    </div>
    <div v-else class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {
    areaData: {
      type: String,
      default: "",
    },
    is_roll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playerOptions: {
        playbackRates: [1.0, 2.0, 3.0], //播放速度
        // autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src:
              "https://image.chuansmart.com/ad/2023/03/23/5a06af427d62317209cc9e281b4bb21b.mp4",
          },
        ],
        autoplay: true,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.playerOptions.sources[0].src = this.areaData;
    this.currentSourceSrc = this.areaData;
    this.$refs.videoPlayer.player.play();
  },
  methods: {
    // 视频播完回调
    onPlayerEnded(player) {
      if (this.is_roll) return false;
      this.$refs.videoPlayer.player.load();
    },
    onPlayerTimeupdate(player) {
      this.durations = player.cache_.currentTime; //当前播放时长
      this.videoSumTime = parseInt(player.cache_.duration); //视频总时长
      // setTimeout(() => {

      //   // this.$refs.videoPlayer.pause();
      // }, this.videoSumTime * 1000);
      // console.log(' onPlayerTimeupdate!', player)
    },
  },
};
</script>
<style lang="scss" scoped>
.none {
  padding-top: 1rem;
  text-align: center;
  font-size: 0.175rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1530a7;
  .el-image {
    width: 2.25rem;
    height: 1.25rem;
  }
}
</style>
