<template>
  <div class="area-sales-container">
    <div class="title1">今日业态销售排行</div>
    <div class="line"></div>
    <pyramid :area_list="area_list" v-if="area_list.length > 0" />
    <div v-else class="none">
      <el-image
        src="https://www.chuansmart.com/storage/screen/newscreen-none.png"
      ></el-image>
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { areaSales2Serve } from "./api";
import pyramid from "@/components/pyramid";
export default {
  name: "",
  components: { pyramid },
  mixins: [],
  props: {},
  data() {
    return {
      option: null,
      arrData: [],
      color: ["#45fed4", "#84a9ef", "#f1e04f", "#dbfe73", "#dbfe73"],
      totalNum: [],
      sum: 0,
      area_list: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // this.$nextTick(() => {
    // });
    this.getAreaSales();
    this.timer = setInterval(() => {
      // 要执行的函数
      this.getAreaSales();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    async getAreaSales() {
      const { code, data } = await areaSales2Serve({
        action: "categories.list",
        limit: 5,
      });
      if (code == 0 && data.length > 0) {
        this.area_list = data;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./css/area-sales-container5";
@import "./css/area-sales-container4";
@import "./css/area-sales-container3";
@import "./css/area-sales-container2";
@import "./css/area-sales-container1";
.area-sales-container {
  position: relative;
  .title1 {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #94fffc;
    text-align: center;
    padding-top: 0.15rem;
  }
  .line {
    width: 5.375rem;
    height: 0.025rem;
    background: linear-gradient(116deg, #070870 0%, #5377ff 47%, #070870 100%);
    margin: 0.15rem auto 0;
  }
  .none {
    padding-top: 1rem;
    text-align: center;
    font-size: 0.175rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1530a7;
    .el-image {
      width: 2.25rem;
      height: 1.25rem;
    }
  }
}
</style>
